<template>
    <v-dialog :value="value" persistent max-width="400px">
        <v-card>
            <v-card-title class="green white--text">
                <v-spacer />
                ОТПРАВКА ШАБЛОННА
                <v-spacer />
                <v-btn dark icon @click="$emit('input')" title="Закрыть"><v-icon>fas
                        fa-times</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="py-4">
                <p>
                    <v-select v-model="template_id" :items="templateData" item-text="name" item-value="template_id"
                        label="Выберите шаблон" outlined></v-select>
                    <v-text-field outlined v-model="params" label="Параметры шаблона" />
                </p>

            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn class="primary" @click="sendTemplate()">Отправить</v-btn>
                <v-btn @click="$emit('input')" title="Закрыть">Закрыть</v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        number: String,
        value: Boolean,
    },
    data() {
        return {
            templateData: [],
            template_id: "",
            params: "",
        }
    },
    created() {
        this.getTemplate();
    },
    watch: {
        template_id() {
            this.params = "";
        }
    },
    methods: {
        async getTemplate() {
            try {
                const res = await this.$axios.get("/wa/template", {
                    params: {
                        sort: JSON.stringify({ "key": "name", "order": "ASC" }),
                        filters: JSON.stringify({ "status": "1" })
                    }
                });
                this.templateData = res.data.data;
            } catch (error) {
                console.error(error)
            }
        },
        async sendTemplate() {
            let params = [];
            if (this.params) {
                for (const el of this.params.split(",")) {
                    params.push(String(el).trim());
                }
            }
            let data = {
                type: "template",
                recipient_number: this.number,
                data: {
                    template_id: this.template_id,
                    params: params
                }
            }
            try {
                await this.$axios.post("/wa/messages", data);
                this.textMessage = "";
                this.$emit('input');
            } catch (error) {
                console.error(error)
                this.$root.$emit("show-info", { text: error.toString(), color: 'red' });
            }
            
        },
    }
}
</script>